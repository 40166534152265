<template>
  <div>
    <loader :loading="requisicaoAberta" texto="Processando..."></loader>
    <CForm>
      <CRow>
        <CCol sm="12">
          <CInput
            label="Identificação"
            :value.sync="$v.opcoesCalculo.identificacao.$model"
            :isValid="verificarCampo('identificacao','opcoesCalculo')"
          />
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol sm="12" id="opcao-fundacao">
          <CFormGroup>
            <label slot="label">Tipo de fundação</label>
            <b-form-radio-group
              v-model="$v.opcoesCalculo.fundacao.$model"
              name="fundacao"
              slot="input"
              @change="gerarOpcoesGeotecnico"
              :state="verificarCampo('fundacao','opcoesCalculo')"
            >
              <b-form-radio value="1">
                <img src="@/assets/SAPATA-32.png" />Sapata
              </b-form-radio>
              <b-form-radio value="2">
                <img src="@/assets/TUBULAO-32.png" />Tubulão
              </b-form-radio>
              <b-form-radio value="3">
                <img src="@/assets/BLOCO-32.png" />Bloco
              </b-form-radio>
            </b-form-radio-group>
          </CFormGroup>
        </CCol>
      </CRow>
      <hr />
      <CRow>
        <CCol sm="12">
          <CSelect
            label="Geotécnico"
            :options="opcoesGeotecnico"
            :value.sync="$v.opcoesCalculo.geotecnico.$model"
            :isValid="verificarCampo('geotecnico','opcoesCalculo')"
          ></CSelect>
        </CCol>
      </CRow>
    </CForm>
  </div>
</template>

<script>
import { required, minValue, between } from "vuelidate/lib/validators";
import mixin from "./../../shared/mixin";
import Loader from "./../Componentes/loader";
import Axios from "axios";
export default {
  name: "FormularioEstrutural",
  mixins: [mixin],
  components: { Loader },
  data() {
    return {
      requisicaoAberta: false,
      opcoesCalculo: {
        identificacao: "",
        fundacao: null,
        geotecnico: 0
      },
      opcoesGeotecnico: [],
      geotecnicos: []
    };
  },
  validations: {
    opcoesCalculo: {
      identificacao: { required },
      fundacao: { required, between: between(1, 3) },
      geotecnico: { required, minValue: minValue(1) }
    }
  },
  methods: {
    gerarOpcoesGeotecnico(fundacao) {
      let opcoesGeotecnico = this.geotecnicos.filter(geotecnico => {
        return geotecnico.fundacao == fundacao;
      });
      opcoesGeotecnico.unshift({
        label: "Selecione um geotécnico",
        value: "0"
      });
      this.opcoesGeotecnico = opcoesGeotecnico;
    },
    obtenhaGeotecnico() {
      let idProjeto = this.obtenhaIdProjeto();
      Axios.get("projeto/" + idProjeto + "/geotecnico")
        .then(resposta => {
          if (resposta.status == 200) {
            this.geotecnicos = resposta.data.retorno.map(geotecnico => {
              if (geotecnico.sondagem !== null)
                return {
                  label: geotecnico.identificacao,
                  value: geotecnico.id,
                  fundacao: geotecnico.fundacao
                };
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    processarEstrutural() {
      this.$v.opcoesCalculo.$touch();
      if (this.$v.opcoesCalculo.$invalid) {
        return;
      }
      this.requisicaoAberta = true;
      let idProjeto = this.obtenhaIdProjeto();
      Axios.post("/projeto/" + idProjeto + "/estrutural", this.opcoesCalculo)
        .then(async resposta => {
          await this.$swal("SolidBase", resposta.data.retorno.mensagem, "info");
          let fundacao = this.otenhaFundacao(this.opcoesCalculo.fundacao);
          this.$router.push({
            name: fundacao,
            params: {
              projetoID: idProjeto,
              estruturalID: resposta.data.retorno.data.id
            }
          });
        })
        .catch(erro => {
          console.log(erro);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    }
  },
  created() {
    this.obtenhaGeotecnico();
    this.$eventoHub.$on("processarEstrutural", this.processarEstrutural);
  },
  destroyed() {
    this.$eventoHub.$off("processarEstrutural", this.processarEstrutural);
  }
};
</script>

<style>
.c-app.c-dark-theme #opcao-fundacao label img {
  background: rgba(255, 255, 255, 0.05);
  padding: 3px;
  margin-right: 4px;
}
.c-dark-theme select option {
  background-color: rgba(255, 255, 255, 0.1);
}
.c-dark-theme .form-control > option {
  color: black;
}
</style>
