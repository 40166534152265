<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-line-style" />&nbsp;Dimensionamento Estrutural
      </CCardHeader>
      <CCardBody>
        <loader :loading="requisicaoAberta"></loader>
        <CDataTable :fields="camposEstrutural" :items="resultadoEstrutural">
          <template slot="no-items-view">
            <div class="text-center my-5">
              <h2 v-if="!requisicaoAberta">Nenhum dimensionamento estrutural encontrado!!!</h2>
            </div>
          </template>
          <td slot="idLinha" class="text-center" slot-scope="{item, index}">{{index+1}}</td>
          <td
            slot="geotecnico"
            slot-scope="{item}"
          >{{item.geotecnico!==null ? item.geotecnico.identificacao : "--"}}</td>
          <td slot="fundacao" slot-scope="{item}">{{otenhaFundacao(item.fundacao)}}</td>
          <td slot="acoes" slot-scope="{item}" class="text-center">
            <CButtonGroup size="sm">
              <CButton color="info" @click="abrirEstrutural(item)">
                <CIcon name="cil-folder-open" />
              </CButton>
              <CButton color="info" @click="gerarPlanta(item.id)">
                <CIcon name="cil-applications" />
              </CButton>
              <CButton color="danger" @click="excluirEstrutural(item)">
                <CIcon name="cil-trash" />
              </CButton>
            </CButtonGroup>
          </td>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CButton color="success" class="float-right" size="sm" @click="cadastrarEstrutural">
          <CIcon name="cil-notes" />&nbsp;Novo
        </CButton>
      </CCardFooter>
    </CCard>
    <CModal
      :show.sync="formularioEstrutural"
      addContentClasses="card"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template slot="header-wrapper">
        <CCardHeader>
          <CIcon name="cil-notes" />&nbsp;Dimensionamento
          <small>Estrutural</small>
          <CButtonClose @click="formularioEstrutural = false" buttonClasses="text-muted close" />
        </CCardHeader>
      </template>
      <template slot="body-wrapper">
        <CCardBody>
          <FormularioEstrutural></FormularioEstrutural>
        </CCardBody>
      </template>
      <template slot="footer-wrapper">
        <CCardFooter>
          <CButton color="success" class="float-right" @click="processarEstrutural">
            <CIcon name="cil-calculator" size="sm"></CIcon>&nbsp;Dimensionar
          </CButton>
        </CCardFooter>
      </template>
    </CModal>
    <EstruturaVisualizacao :show.sync="estruturalVisualizacao"></EstruturaVisualizacao>
  </div>
</template>
<script>
import Meta from "./../../shared/meta-dados";
import mixin from "./../../shared/mixin";
import Loader from "./../Componentes/loader";
import FormularioEstrutural from "./FormularioEstrutural";
import EstruturaVisualizacao from "./EstruturaVisualizacao";
import Axios from "axios";
export default {
  name: "Estrutural",
  components: { Loader, FormularioEstrutural, EstruturaVisualizacao },
  mixins: [mixin],
  data() {
    return {
      estruturalVisualizacao: false,
      formularioEstrutural: false,
      requisicaoAberta: false,
      resultadoEstrutural: [],
      camposEstrutural: [
        { key: "idLinha", sortable: true, label: "#", _classes: "text-center" },
        {
          key: "identificacao",
          sortable: true,
          label: "Identificação"
        },
        {
          key: "geotecnico",
          sortable: true,
          label: "Geotécnico"
        },
        {
          key: "fundacao",
          sortable: true,
          label: "Fundação"
        },
        {
          key: "acoes",
          sortable: true,
          label: "Ação",
          _classes: "text-center"
        }
      ]
    };
  },
  methods: {
    obtenhaEstrutural() {
      let idProjeto = this.obtenhaIdProjeto();
      this.requisicaoAberta = true;
      Axios.get("/projeto/" + idProjeto + "/estrutural/")
        .then(resposta => {
          this.resultadoEstrutural = resposta.data.retorno;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.requisicaoAberta = false;
        });
    },
    gerarPlanta(idEstrutural) {
      this.estruturalVisualizacao = true;
      this.$eventoHub.$emit("exibirPlanta", idEstrutural);
    },
    excluirEstrutural(estrutural) {
      this.$swal({
        title: "Atenção",
        icon: "warning",
        text: Meta.obtenhaMensagem(1011, "pt-br"),
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let idProjeto = this.obtenhaIdProjeto();
          let idEstrtural = estrutural.id;
          this.requisicaoAberta = false;
          Axios.delete("/projeto/" + idProjeto + "/estrutural/" + idEstrtural)
            .then(resposta => {
              this.$eventoHub.$emit("adicionarMensagemAviso", {
                mensagem: resposta.data.retorno,
                classe: "bg-success"
              });
              this.obtenhaEstrutural();
            })
            .catch(erro => {
              this.$eventoHub.$emit("adicionarMensagemAviso", {
                mensagem: erro.response.data.retorno,
                classe: "bg-danger"
              });
            })
            .finally(() => {
              this.requisicaoAberta = false;
            });
        }
      });
    },
    cadastrarEstrutural() {
      this.formularioEstrutural = true;
    },
    processarEstrutural() {
      this.$eventoHub.$emit("processarEstrutural", true);
    },
    abrirEstrutural(item) {
      let fundacao = this.otenhaFundacao(item.fundacao);
      let idProjeto = this.obtenhaIdProjeto();
      this.$router.push({
        name: fundacao,
        params: {
          projetoID: idProjeto,
          estruturalID: item.id
        }
      });
    },
    fecharDesenhoPlanta() {
      this.estruturalVisualizacao = false;
    }
  },
  created() {
    this.obtenhaEstrutural();
    this.$eventoHub.$on("fecharJanelaVisualizacao", this.fecharDesenhoPlanta);
  },
  destroyed() {
    this.$eventoHub.$off("fecharJanelaVisualizacao", this.fecharDesenhoPlanta);
  }
};
</script>

<style>
</style>
